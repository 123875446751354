:root {
  /* Only use CSS vars if not using IE */
  --flash-anim-duration: 10s;
}

body {
  margin: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  /* font-family: 'Source Sans Pro', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  background-color: #fafafa;
  font-size: 1.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
select {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  border: 1px solid #cecece;
  color: #121212;
  font-size: 1em;
  border-radius: 0px;
  padding: 5px 15px;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

/* select {
  border-radius: 20px 6px 6px 20px;
} */

h1,
h2,
h3,
h4,
h5 {
  /* font-family: 'Abel', sans-serif; */
  font-family: 'Aleo', serif;
}

button {
  /* font-family: 'Abel', sans-serif; */
  font-family: 'Open Sans', serif;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-size: 1em;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #cecece;
  color: #121212;
  font-size: 1em;
  /* border-radius: 20px 20px 4px 20px; */
  padding: 10px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid #1591bf;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

.react-select-filters input:focus,
.react-select-counties input:focus {
  box-shadow: none;
}

.basic-multi-select input:focus,
textarea:focus {
  border: 1px solid transparent;
  box-shadow: none;
}

input[type='range'] {
  padding: 0px !important;
  border-radius: 0px;
}

input[type='range']:focus {
  border: 1px solid transparent;
  box-shadow: none;
}

textarea:focus {
  border-radius: 20px 20px 4px 20px;
}

p {
  margin-top: 20px;
  margin-bottom: 10px;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

li {
  margin: 8px 0px;
  margin-left: 10px;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.Toastify__toast-container--top-center {
  top: 50px !important;
}

.regular-checkbox {
  margin-right: 10px;
}

.leaflet-div-icon {
  background-color: transparent;
  border: none;
  text-align: center;
}

.marker-svg {
  box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.3);
  height: 28px;
  width: 28px;
  border-radius: 20px;
  border: 1px solid #fff;
  -webkit-transform: rotate(-90deg) scaleY(-1);
  transform: rotate(-90deg) scaleY(-1);
}

.leaflet-control-container .leaflet-top {
  position: absolute;
  z-index: 400;
  pointer-events: none;
}

.cluster-marker-grey {
  background-color: #c7c7c7;
  border: 2px solid #777777;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.cluster-marker-blue {
  background-color: #00a7d9;
  border: 2px solid #07335c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker-important {
  background: url('../assets/icons/alert-marker.svg');
  background-position: center;
  background-color: #0481b0;
  border: 2px solid #0481b0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Ensure important markers stack on top of all other markers */
  z-index: 9999 !important;
}

/* The search form height is aligned with right and left buttons  */
.leaflet-control-geosearch.active form {
  height: 30px;
}

/* The search bar has a lower z-index than the county dropdown selector */
.leaflet-geosearch-bar {
  z-index: 400 !important;
}

/* Mobile view has a smaller search bar (750px) */
@media screen and (max-width: 700px) {
  /* The search form height is aligned with right and left buttons  */
  .leaflet-control-geosearch.active form {
    width: 185px;
    height: 30px;
  }

  /* The input in the search form is centered */
  .glass {
    position: relative;
    top: 2px;
    left: -25px;
  }
}

.leaflet-control-geosearch input {
  border: none;
  box-shadow: none;
  padding: 0px !important;
}
